import React from "react";

const CarrerForm = ({
  HandleInputChange,
  career,
  setCareer,
  onSubmit,
  isSubmitting,
  handleCheckboxChange,
  isShown,
  resMsg,
}) => {
  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile.length);

    try {
      const base64Resume = await convertToBase64(selectedFile);

      setCareer((prevData) => ({
        ...prevData,
        resume: {
          filename: selectedFile.name,
          // content: base64Resume.split(",")[1],
        },
        PdfBase64: base64Resume.split(",")[1],
      }));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <form action="" onSubmit={onSubmit}>
      <div>
        {/* USer Details Start */}
        <div className="user-details block w-full lg:flex flex-wrap justify-between mt-6  ">
          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Name<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={career.name}
              onChange={HandleInputChange}
              placeholder="Please enter your full name"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Company<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              name="company"
              value={career.company}
              onChange={HandleInputChange}
              type="text"
              placeholder="Please enter company name"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              E-mail<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              name="email"
              value={career.email}
              onChange={HandleInputChange}
              type="email"
              placeholder="Please enter your E-Mail ID"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Phone<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="phone"
              value={career.phone}
              onChange={HandleInputChange}
              placeholder="Please enter your valid phone number"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Role<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="role"
              value={career.role}
              onChange={HandleInputChange}
              placeholder="Please enter your current role"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
            <div className="flex space-x-2">
              <div className="mt-3 border-2 rounded-md border-[#03083E] w-32 text-center cursor-pointer">
                <label className="text-[#03083E] text-[15px] font-medium ">
                  Upload Resume
                  <input
                    type="file"
                    accept=".pdf"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    className="cursor-pointer"
                  />
                </label>
              </div>
              <div className="text-xs text-gray-600 font-semibold mt-4">
                (Max Size 4 MB)
              </div>
            </div>
            {career.resume && career.resume.filename}
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Experience in years<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="number"
              name="experience"
              value={career.experience}
              onChange={HandleInputChange}
              placeholder="Please enter your total experience"
              required
              className="text-[10px] md:text-[14px] italic formInput"
              disabled={career.notRelevant}
            />
            <input
              type="checkbox"
              className="mt-2"
              checked={career.notRelevant}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor=""
              className="text-[14px] font-medium text-[#03083E] ms-1"
            >
              Check box if your experience is not relevant
            </label>
          </div>
        </div>
        {/* USer Details End */}
        {isShown && <div className="text-red-500 font-medium">{resMsg}</div>}
        {/* Submit Button */}

        <div className="mt-10 text-center">
          {isSubmitting ? (
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <button type="submit" className="submit-button">
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default CarrerForm;
