import React, { useState } from "react";

const Marquee = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <div className="About-us-section">
        <div
          className={`text_anim_wrap wf-section sm_none ${
            isHovered ? "paused" : ""
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="text-anim-marq-wrap hide-on-sm marque-overflow">
            <div className="marquee">
              <div className="text-anim-holder">
                <div className="marquee-elements">
                  <li className="  font-[300] text-[50px]  p-8">Electronics</li>
                  <li className="font-semibold text-[50px]">Automation</li>
                  <li className="  font-[300] text-[50px]  p-8">Robotics</li>
                  <li className="font-semibold text-[50px] ">IoT</li>
                  <li className="  font-[300] text-[50px]  p-8">Engineering</li>
                  <li className="font-semibold text-[50px] ">Design</li>
                  <li className="  font-[300] text-[50px]  p-8">
                    Simulation & Analytics
                  </li>
                  <li className="font-semibold text-[50px] ">
                    Startup Program
                  </li>
                  <li className="  font-[300] text-[50px]  p-8">Electronics</li>
                  <li className="font-semibold text-[50px] ">Automation</li>
                  <li className=" font-[300] text-[50px]  p-8">Robotics</li>
                  <li className="font-semibold text-[50px] ">IoT</li>
                  <li className="  font-[300] text-[50px]  p-8">Engineering</li>
                  <li className="font-semibold text-[50px] ">Design</li>
                  <li className="  font-[300] text-[50px]  p-8">
                    Simulation & Analytics
                  </li>
                  <li className="font-semibold text-[50px] ">
                    Startup Program
                  </li>
                  <li className="  font-[300] text-[50px]  p-8">Electronics</li>
                  <li className="font-semibold text-[50px] ">Automation</li>
                  <li className="  font-[300] text-[50px]  p-8">Robotics</li>
                  <li className="font-semibold text-[50px] ">IoT</li>
                  <li className="  font-[300] text-[50px]  p-8">Engineering</li>
                  <li className="font-semibold text-[50px] ">Design</li>
                  <li className="  font-[300] text-[50px]  p-8">
                    Simulation & Analytics
                  </li>
                  <li className="font-semibold text-[50px] ">
                    Startup Program
                  </li>
                  <li className="  font-[300] text-[50px]  p-8">Electronics</li>
                  <li className="font-semibold text-[50px] ">Automation</li>
                  <li className="  font-[300] text-[50px]  p-8">Robotics</li>
                  <li className="font-semibold text-[50px] ">IoT</li>
                  <li className="  font-[300] text-[50px]  p-8">Engineering</li>
                  <li className="font-semibold text-[50px] ">Design</li>
                  <li className="  font-[300] text-[50px]  p-8">
                    Simulation & Analytics
                  </li>
                  <li className="font-semibold text-[50px] ">
                    Startup Program
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marquee;
