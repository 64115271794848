import React, { useEffect } from "react";
import EmbeddedHardware from "../assets/images/Embeddedhardware.png";
import EmbeddedSoftware from "../assets/images/EmbeddedSoftware.png";
import WirelessSystems from "../assets/images/wiresystems.png";
import TechnologyConsulting from "../assets/images/Technologyconsulting.png";
import UserInterface from "../assets/images/userinterface.png";
import ServiceBanner from "../components/Industries";
import AOS from "aos";
import "aos/dist/aos.css";
import Fade from "react-reveal/Fade";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const OurServices = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);
  const particlesOptions = {
    fpsLimit: 150,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        // onHover: {
        //   enable: true,
        //   mode: "repulse",
        // },
        // resize: false,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 2,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 900,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },

      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };
  return (
    <div>
      <div className="Our-Services-Container">
        <div class="background-image"></div>
        <Particles
          id="tsparticles"
          options={particlesOptions}
          init={particlesInit}
          loaded={particlesLoaded}
        />
        <div className="p-[3rem] ">
          <div className="Ourservicesanimatesvg"></div>
          <div className="Ourservicesanimatesvg_2"></div>

          <Fade bottom>
            <h1 className="text-[48px] font-medium text-white pt-[3rem]  ">
              Our Product Engineering Services
            </h1>
            <hr />
          </Fade>
          <Fade bottom>
            <p className="text-[20px] mt-4 leading-2 text-white text-justify ">
              We help accelerate time-to-market and meet product development
              goals through ground-up design, engineering & validation support
              all the way from napkin sketches and concept outlines to
              production-ready & standards-compliant designs
            </p>
          </Fade>

          <div className="p-10 our-services-res items-center">
            <div className="services-res grid grid-cols-1 gap-12  py-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:px-48 lg:px-[5rem]">
              <Fade bottom>
                <div className="overflow-hidden rounded-lg">
                  <a href="/services/embedded-hardware-services">
                    <div className="overflow-hidden rounded-lg">
                      <img
                        className="w-full hover:scale-[1.1] ease-in duration-300"
                        src={EmbeddedHardware}
                        alt="Embedded Hardware Design"
                      />{" "}
                    </div>
                    <h4 className="font-semibold text-center OurServiceHeading">
                      Embedded Hardware Design
                    </h4>
                  </a>
                </div>
              </Fade>
              <Fade bottom>
                <div className="overflow-hidden rounded-lg">
                  <a href="/services/embedded-software-services">
                    <div className="w-full overflow-hidden">
                      <img
                        className="w-full hover:scale-[1.1] ease-in duration-300"
                        src={EmbeddedSoftware}
                        alt="Embedded Software Development"
                      />
                    </div>
                    <h4 className="font-semibold text-center OurServiceHeading">
                      Embedded Software Development
                    </h4>
                  </a>
                </div>
              </Fade>
              <Fade bottom>
                <div className="overflow-hidden rounded-lg">
                  <a href="/services/wireless-systems-development">
                    <div className="w-full overflow-hidden">
                      <img
                        className="w-full hover:scale-[1.1] ease-in duration-300"
                        src={WirelessSystems}
                        alt="Wireless Systems Development"
                      />
                    </div>
                    <h4 className="font-semibold text-center OurServiceHeading">
                      Wireless Systems Development
                    </h4>
                  </a>
                </div>
              </Fade>
              <Fade bottom>
                <div className="overflow-hidden rounded-lg lg:translate-x-[60%]">
                  <a href="/services/technology-consulting-services">
                    <div className="w-full overflow-hidden">
                      <img
                        className="w-full hover:scale-[1.1] ease-in duration-300"
                        src={TechnologyConsulting}
                        alt="Technology Consulting"
                      />
                    </div>
                    <h4 className="font-semibold text-center OurServiceHeading">
                      Technology Consulting
                    </h4>
                  </a>
                </div>
              </Fade>
              <Fade bottom>
                <div className="overflow-hidden rounded-lg sm:translate-x-[60%]">
                  <a href="/services/user-interface-development">
                    <div className="w-full overflow-hidden">
                      <img
                        className="w-full hover:scale-[1.1] ease-in duration-300"
                        src={UserInterface}
                        alt="User Interface Development"
                      />
                    </div>
                    <h4 className="font-semibold text-center OurServiceHeading">
                      User Interface Development
                    </h4>
                  </a>
                </div>
              </Fade>
              {/* <div className="overflow-hidden rounded-lg">
                <a href="/services/cloud-services-and-integration">
                  <div className="w-full overflow-hidden">
                    <img
                      className="w-full hover:scale-[1.1] ease-in duration-300"
                      src={CloudServices}
                      alt="Cloud Service and Integration"
                    />
                  </div>
                  <h4 className="font-semibold text-center OurServiceHeading">
                    Cloud Service and Integration
                  </h4>
                </a>
              </div> */}
            </div>
          </div>
          <a href="/services" className="know-more">
            Know More
          </a>

          <Fade bottom>
            <h1 className="pt-10 lg:pb-10 lg:text-[48px] sm:text-[50px] font-medium text-black">
              Industries Served
            </h1>
          </Fade>
        </div>
        <div className="lg:p-[3rem] lg:pt-0 ">
          <ServiceBanner />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
