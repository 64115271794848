import React from "react";
import EvManagement from "./SmartBulidngSlider/EvManagement";
import AccessControl from "./SmartBulidngSlider/AccessControl";
import Monitoring from "./SmartBulidngSlider/Monitoring";
import ManagementSolutions from "./SmartBulidngSlider/ManagementSolutions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Smart = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };
  return (
    <div>
      {/* DeskTop View */}
      <div className="mt-4 ">
        <div className="lg:block hidden">
          <Slider
            {...settings}
            className="scroll-slider bg-[#dffffc] p-10 xl:p-12 xl:pt-0 mx-28 my-10 filter"
          >
            <div className="connect-container">
              <EvManagement />
            </div>
            <div className="connect-container">
              <AccessControl />
            </div>
            <div className="connect-container">
              <Monitoring />
            </div>
            <div className="connect-container">
              <ManagementSolutions />
            </div>
          </Slider>
        </div>

        {/* Responsive View */}

        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[800px] ">
          <EvManagement />
        </div>
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[700px]  ">
          <AccessControl />
        </div>
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[800px]  ">
          <Monitoring />
        </div>
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[890px] ">
          <ManagementSolutions />
        </div>
      </div>
    </div>
  );
};

export default Smart;
