import React from "react";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
import "./index.css";
import signalprocessingimg from "../../assets/Casestudiesimgs/signalprocessingimg.png";
const Signalprocessingboard = () => {
  return (
    <div className="">
      <div className="modulecontainer">
        <NavBar />
        <h2 class="text-center sm:text-7xl sm:text-center text-6xl title-font font-semibold text-white mt-4 mb-4 leading-relaxed">
          SIGNAL PROCESSING BOARD
        </h2>
      </div>
      <div className="clientbgcontainer">
        <section class="text-gray-600 body-font overflow-hidden">
          <div class="lg:px-14 py-24 mx-auto">
            <div class="flex flex-wrap ">
              <div class="p-12 lg:w-1/2 flex flex-col items-start ">
                <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                  The client's challenge
                </h2>
                <p class="leading-10 mb-8 text-white text-[20px]">
                  Lorem Ipsum Is Simply Dummy Text Of The Printing. Lorem Ipsum
                  Is Simply Dummy Text Of The Printing. Lorem Ipsum Is Simply
                  Dummy Text Of The Printing. Lorem Ipsumis Simply Dummy Text Of
                  The Printing.
                </p>
                <div className="grid grid-cols-2 gap-2 mb-8">
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border">Parameterss Captured</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border">Communication protocols</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border ">Environmental requirements</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border ">Power and bandwidth requirements</div></div>
                </div>
                <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc ">
                    Multiple 3rd party analog I/O modules.
                  </li>
                  <li className="ml-6 list-disc ">Separated power supply.</li>
                  <li className="ml-6 list-disc ">Redundant ports and I/O.</li>
                  <li className="ml-6 list-disc ">
                    No highvoltage / ground-loop protection.
                  </li>
                </ul>
                 <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                  Our Solutions 
                </h2>

                <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc">
                    400% increase in communication bandwidth
                  </li>
                  <li className="ml-6 list-disc ">
                    80% reduction in cost of production
                  </li>
                  <li className="ml-6 list-disc ">
                    Expanded protocol compatibility
                  </li>
                  </ul>
                  <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                    Benefits 
                </h2>
                <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc ">
                    Integrated board for I/O and power supply
                  </li>
                  <li className="ml-6 list-disc ">
                    Custom analog and digital I/O configuration
                  </li>
                  <li className="ml-6 list-disc ">
                    Hardware compatibility with multiple industrial protocols
                  </li>
                </ul>
              </div>

              <div class="p-12 md:w-1/2 flex flex-col items-start">
                <img alt="blog" src={signalprocessingimg}   className="w-full"   />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Signalprocessingboard;
