import React from "react";
import Footer from "../components/Layout/Footer";
import WhoAreWe from "../components/AboutUs/WhoAreWe";
import OurCulture from "../components/AboutUs/OurCulture";
import OurJourney from "../components/AboutUs/Statistics";

const App = () => {
  return (
    <div>
      <WhoAreWe />
      <OurCulture />
      <OurJourney />
      <Footer />
    </div>
  );
};

export default App;
