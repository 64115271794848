import React from "react";
import MobilityHero from "../components/OurSolutions/Mobility/MobilityHero";
import Footer from "../components/Layout/Footer";

const Mobility = () => {
  return (
    <div>
      <MobilityHero />
      <Footer />
    </div>
  );
};

export default Mobility;
