import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Mobilitys = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <Slider
        {...settings}
        className="scroll-slider bg-[#dffffc] p-10 xl:p-12 xl:pt-0 mx-28 my-10 filter"
      >
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] h-[650px]">
          <h2 className="mobility-gradient text-center mt-[15%]  font-medium text-[70px] ">
            Mobility Coming Soon
          </h2>
        </div>
      </Slider>
      <div className="lg:hidden xl:hidden  bg-[#dffffc]  ">
        <h2 className="mobility-gradient text-center mt-[15%]  font-medium text-[30px] ">
          Mobility Coming Soon
        </h2>
      </div>
    </>
  );
};

export default Mobilitys;
