import React from "react";

const PartnerShipForm = ({
  HandleInputChange,
  Partnership,
  onSubmit,
  isSubmitting,
  isShown,
  resMsg,
}) => {
  return (
    <form action="" onSubmit={onSubmit}>
      <div>
        <div className="user-details block w-full lg:flex flex-wrap justify-between mt-6">
          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Name<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={Partnership.name}
              onChange={HandleInputChange}
              placeholder="Please enter your full name"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Company
            </span>
            <input
              autoComplete="off"
              type="text"
              name="company"
              value={Partnership.company}
              onChange={HandleInputChange}
              placeholder="Please enter company name"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              E-mail
            </span>
            <input
              autoComplete="off"
              type="email"
              name="email"
              value={Partnership.email}
              onChange={HandleInputChange}
              placeholder="Please enter your E-Mail ID"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Phone
            </span>
            <input
              autoComplete="off"
              type="text"
              name="phone"
              value={Partnership.phone}
              onChange={HandleInputChange}
              placeholder="Please enter your valid phone number"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>
        </div>

        <div className="mt-4 mb-28">
          <span className="block font-semibold text-[20px] lg:text-[23px] text-black">
            Talk to us
          </span>
          <textarea
            cols="3"
            rows="5"
            type="text"
            name="description"
            value={Partnership.description}
            onChange={HandleInputChange}
            placeholder="Please share your interests"
            className="text-[10px] md:text-[14px] italic formInputText"
          ></textarea>
          <p className="text-[10px] font-medium text-[#6D6D6D] italic mt-1 text-end">
            Do not exceed 500 words
          </p>
        </div>

        {/* Submit Button */}

        {isShown && <div className="text-red-500 font-medium">{resMsg}</div>}

        <div className="mt-10 text-center">
          {isSubmitting ? (
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <button type="submit" className="submit-button">
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default PartnerShipForm;
