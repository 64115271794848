import React, { useState } from "react";

const ClientCard = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <div className="">
        <div
          className={`text_anim_wrap wf-section sm_none ${
            isHovered ? "paused" : ""
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="text-anim-marq-wrap hide-on-sm marque-overflow ">
            <div className="marquee-clientcard">
              <div className="text-anim-holder_clientcard">
                <div className="py-12 md:space-x-40 space-x-10 marquee-elements-clientcard">
               
                <img
                    src={require("../assets/images/Clientcardimg/Goodrej.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/EL.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/GDSCorp.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/Titan.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/Finna.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Meredia.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Presso.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Pulsenics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/SwapRobotics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  
                   <img
                    src={require("../assets/images/Clientcardimg/Unbox.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Artyc.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />

<img
                    src={require("../assets/images/Clientcardimg/Goodrej.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/EL.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/GDSCorp.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                 
                  <img
                    src={require("../assets/images/Clientcardimg/Finna.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Meredia.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Presso.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Pulsenics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/SwapRobotics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  
                   <img
                    src={require("../assets/images/Clientcardimg/Unbox.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Artyc.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />

<img
                    src={require("../assets/images/Clientcardimg/Goodrej.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/EL.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/GDSCorp.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  
                  <img
                    src={require("../assets/images/Clientcardimg/Finna.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Meredia.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Presso.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Pulsenics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/SwapRobotics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   
                   <img
                    src={require("../assets/images/Clientcardimg/Unbox.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Artyc.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />

<img
                    src={require("../assets/images/Clientcardimg/Goodrej.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/EL.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/GDSCorp.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  
                  <img
                    src={require("../assets/images/Clientcardimg/Finna.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Meredia.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Presso.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Pulsenics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/SwapRobotics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   
                   <img
                    src={require("../assets/images/Clientcardimg/Unbox.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Artyc.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />

<img
                    src={require("../assets/images/Clientcardimg/Goodrej.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/EL.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/GDSCorp.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  
                  <img
                    src={require("../assets/images/Clientcardimg/Finna.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Meredia.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Presso.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Pulsenics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/SwapRobotics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   
                   <img
                    src={require("../assets/images/Clientcardimg/Unbox.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Artyc.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />

<img
                    src={require("../assets/images/Clientcardimg/Goodrej.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/EL.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                  <img
                    src={require("../assets/images/Clientcardimg/GDSCorp.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                 
                  <img
                    src={require("../assets/images/Clientcardimg/Finna.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Meredia.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Presso.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Pulsenics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/SwapRobotics.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                 
                   <img
                    src={require("../assets/images/Clientcardimg/Unbox.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                   <img
                    src={require("../assets/images/Clientcardimg/Artyc.svg").default}
                    alt="Schleyer Halle logo"
                    className="pb-10 sm:pb-0 "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
