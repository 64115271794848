import React from "react";
import Act from "./IIOT/ActIiot";
import Ana from "./IIOT/AnalyzeIiot";
import Con from "./IIOT/ConnectIiot";
import Mea from "./IIOT/MeasureIiot";

const IIoTCard = () => {
  return (
    // IIoT Card Animation
    <div className="">
      <main>
        <ul id="cards">
          <li className="card" id="card_1">
            <div className="card__content">
              <div>
                <Con />
              </div>
            </div>
          </li>
          <li className="card" id="card_2">
            <div className="card__content">
              <div>
                <Mea />
              </div>
            </div>
          </li>
          <li className="card" id="card_3">
            <div className="card__content">
              <div>
                <Ana />
              </div>
            </div>
          </li>
          <li className="card" id="card_4">
            <div className="card__content">
              <div>
                <Act />
              </div>
            </div>
          </li>
        </ul>
      </main>
    </div>
  );
};

export default IIoTCard;
