import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Measure from "../../../assets/images/MeasureIiot.png";


const MeasureIiot = () => {
  return (
    <div>
      <div className="fac-3 access-main-container lg:pt-[3rem] lg:pb-[3rem]  pb-16">
        <div>
          <div className="block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="text-[48px] heading-text-gradient  mb-5 font-semibold">
                  Measure
                </h2>
                <p className="pt-2 lg:w-[80%]  text-[24px] text-justify text-black font-medium ">
                  Measure and collect real time data of your machines
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Customize how you would like to view your machines.
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Categorize by zones and areas within your factory.
                  </li>

                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Keep your most critical machines pinned to dashboards.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <img src={Measure} className="w-[100%] access" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeasureIiot;
