import React from "react";
import analyze from "../../../assets/images/Analyze/Analyze.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Analyze = () => {
  return (
    <div className="grid lg:grid-cols-2 items-center justify-items-center">
      <div className="pt-2  xl:pl-[3rem]">
        <div className="md:pt-[5rem] ">
          <h2 className="text-[#07D4FA] font-medium text-[50px] mb-3 lg:mb-6">
            Analyze
          </h2>
          <ul className="pt-5 text-black text-[18px] font">
            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Mark certain actions and see which users have triggered it.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Set notifications for scheduled maintenance activities so that
                you don't miss out on any.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                See alarms and warnings when a process/machine parameter exceeds
                threshold.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Create custom views to see trends based on zones, machines and
                their parameters.
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div className="connect-image-conatiner lg:pt-[7rem]">
        <img src={analyze} alt="" className="Analyze-img" />
      </div>
    </div>
  );
};

export default Analyze;
