import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className=" footer-container body-font">
        <div className="container flex flex-col flex-wrap w-full  md:px-[3rem] py-16 mx-auto overflow-hidden md:items-center lg:items-start md:flex-row md:flex-nowrap">
          <div className="flex-shrink-0 mx-auto text-center md:ml md:text-left w-80">
            <div className="flex items-center justify-center font-medium text-gray-900 title-font md:justify-start">
              <span className="ml-1.8 text-xl">
                <a href="/">
                  <img
                    src={require("../../assets/images/logo.svg").default}
                    className="footer-logo-image"
                    alt="My Logo"
                  />
                </a>
              </span>
            </div>
            <p className="pt-2 m-0 mt-2 text-[16px] text-white ml-0 leading-9">
              We Provide Cutting Edge Solutions To Hardware And Software Clients
              Across Various Industries
            </p>
          </div>
          <div className="flex flex-wrap flex-grow mt-10 -mb-10 text-center footert lg:pl-20 md:mt-0 md:text-left">
            <div className="w-1/2 pr-6 text-white lg:px-4 lg:w-1/4 md:w-1/2">
              <div className="mb-10 space-y-6 text-1xl list-none leading-[2rem]">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/iiot"> Industrial IoT</a>
                </li>
                <li>
                  <a href="/smartbuildings"> Smart Building</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
              </div>
            </div>
            <div className="w-1/2 pr-6 text-white lg:px-4 lg:w-1/4 md:w-1/2">
              <div className="mb-10 space-y-6 text-1xl list-none leading-[2rem]">
                <li>
                  <a href="/about">About Us</a>
                </li>
                {/* <li>
                  <a href="/media">StartUp Program</a>
                </li> */}
                <li>
                  <a href="/career">Careers</a>
                </li>
                <li>
                  <a href="/our-work">Our Work</a>
                </li>
                <li>
                  <a href="/team"> Our Team</a>
                </li>
              </div>
            </div>
            <div className="w-1/2 pr-6 text-white lg:px-4 lg:w-1/4 md:w-1/2">
              <div className="mb-10 space-y-6 text-1xl list-none leading-[2rem]">
                <li>
                  <a href="/media"> Media</a>
                </li>
                <li>
                  <a href="/process">Our Process</a>
                </li>
                <li>
                  <a href="/policy/privacy"> Privacy Policy</a>
                </li>

                {/* <li>
                  <a href="#">Cookie Policy</a>
                </li> */}
              </div>
            </div>
            <div className="w-1/2 pr-6 text-white lg:px-4 lg:w-1/4 md:w-1/2">
              <div className="mb-10 space-y-6 text-1xl list-none leading-[2rem] ">
                <li>
                  <span href="#" className="font-medium">
                    {" "}
                    Contact Us
                  </span>
                </li>
                <li className="flex items-center justify-center md:justify-start">
                  <FontAwesomeIcon icon={faPhone} className="flex text-white" />
                  <a className="flex flex-row gap-3 footer-gap footer-components ms-2">
                    +91 9177444992
                  </a>
                </li>
                <li className="flex items-center">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="flex text-white"
                  />
                  <a
                    href="mailto:info.edge@Elogictech.com"
                    className="flex flex-row footer-gap footer-components ms-2"
                  >
                    info.edge@Elogictech.com
                  </a>
                </li>
                <li>
                  {/* <FontAwesomeIcon icon={faLinkedin} /> */}
                  <div className=" px-2">
                    <p className=" font-medium text-res"> Follow Us On</p>
                    <a href="https://www.linkedin.com/company/elt-edge/?viewAsMember=true">
                      <img
                        src={require("../../assets/images/linkedin.png")}
                        alt=""
                        className="in-icon w-9 mx-auto md:mx-0"
                      />
                    </a>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
