import React, { useRef, useState } from "react";
import HorizontalScroll from "react-scroll-horizontal";
import "./index.css";

const OurJourney = () => {
  const scrollContainerRef = useRef(null);
  const [scrollX, setScrollX] = useState(0);

  let startX = null;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (startX !== null) {
      const currentX = e.touches[0].clientX;
      const deltaX = startX - currentX;
      startX = currentX;
      const newScrollX = scrollX + deltaX;

      // Ensure scrollX stays within bounds
      const maxScroll =
        scrollContainerRef.current.scrollWidth -
        scrollContainerRef.current.clientWidth;
      if (newScrollX >= 0 && newScrollX <= maxScroll) {
        setScrollX(newScrollX);
      }
    }
  };

  const handleTouchEnd = () => {
    startX = null;
  };
  return (
    <>
      {/* ****** Our Journy Large device veiw ****** */}

      <div className="horizontolContainer ">
        <h1 className="our-journey font-semibold text-[40px] pl-[3rem] pr-[3rem] pt-[3rem]  ">
          Our Journey
        </h1>
        <HorizontalScroll
          reverseScroll={true}
          // pageLock={true}
          config={{ stiffness: 170, damping: 26 }}
          animValues={10}
          ref={scrollContainerRef}
          style={{ overflowX: "scroll" }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="list-container6 pt-16 p-10 ">
            <div className="one">
              <span className="year ">1998</span>
              <span className="journey">eLogic inception</span>
            </div>
            <div className="two">
              <span className="year">1999</span>
              <span className="journey">100% export oriented unit</span>
            </div>
            <div className="one">
              <span className="year">2001</span>
              <span className="journey">Us Entity Incorporation</span>
            </div>
            <div className="two">
              <span className="year">2002</span>
              <span className="journey">One Stop Service Provider</span>
            </div>
            <div className="one">
              <span className="year">2005</span>
              <span className="journey">Global Leaders</span>
            </div>
            <div className="two">
              <span className="year">2015</span>
              <span className="journey">Incorporated in Dubai</span>
            </div>
            <div className="one">
              <span className="year">2015</span>
              <span className="journey">AIA registered</span>
            </div>
            <div className="two">
              <span className="year">2019</span>
              <span className="journey">AISC registered</span>
            </div>
            <div className="one">
              <span className="year">2021</span>
              <span className="journey">eLogicTech Edge inception</span>
            </div>
          </div>
        </HorizontalScroll>
      </div>
    </>
  );
};

export default OurJourney;
