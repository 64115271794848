import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../Layout/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ServicesForm from "./ServicesForm";
import PartnerShipForm from "./PartnerShipForm";
import CarrerForm from "./CarrerForm";
import SolutionForm from "./SolutionForm";
import Fade from "react-reveal/Fade";

const Contact = () => {
  const [formType, setFormType] = useState("services");
  const [solution, setSolution] = useState([]);
  const [services, setServices] = useState([]);

  const [isShown, setIsShown] = useState(false);
  const [resMsg, setResMsg] = useState("");
  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        setIsShown(false);
      }, 2000);
    }
  }, [isShown]);

  // Services
  const [formdata, setFormData] = useState({
    selectionType: "services",
    name: "",
    email: "",
    company: "",
    phone: "",
    services: "",
    description: "",
  });

  // Partnership
  const [Partnership, setPartnership] = useState({
    selectionType: "partnership",
    name: "",
    email: "",
    company: "",
    phone: "",
    description: "",
  });

  // career
  const [career, setCareer] = useState({
    selectionType: "career",
    name: "",
    email: "",
    company: "",
    phone: "",
    role: "",
    resume: "",
    experience: "",
    notRelevant: false,
    PdfBase64: "",
  });

  // Solutions
  const [solutions, setSolutions] = useState({
    selectionType: "solution",
    name: "",
    email: "",
    company: "",
    phone: "",
    solution: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRadioChange = (event) => {
    setFormData(() => ({
      selectionType: event.target.value,
    }));
    setFormType(event.target.value);
  };

  // Services Input
  const HandleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    console.log(services);
  };

  // partnerShip Input
  const HandlePartInputChange = (e) => {
    console.log(Partnership);
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;
    console.log(e.target);

    setPartnership((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    console.log(Partnership);
  };

  // Solution Input
  const HandleSoluInputChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setSolutions((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  // Career Input
  const HandleCareerInputChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setCareer((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleCheckboxChange = () => {
    setCareer((prevData) => ({
      ...prevData,
      notRelevant: !prevData.notRelevant,
      experience: prevData.notRelevant ? "" : prevData.experience,
    }));
  };

  // Service From Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://gaysgxqov0.execute-api.ap-south-1.amazonaws.com/formData",
        formdata
      );

      if (response.status === 200) {
        setFormData({
          selectionType: "services",
          name: "",
          email: "",
          company: "",
          phone: "",
          services: [],
          description: "",
        });
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submitted successfully!");
        setServices([]);
      } else {
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submission failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsSubmitting(false);
      setIsShown(true);
      setResMsg("Form submission failed.");
    }
  };

  // Career From Submit
  const handlecareerSubmit = async (e) => {
    e.preventDefault();
    console.log(career);
    if (career.PdfBase64.length === 0) {
      return alert("Please select the resume");
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://gaysgxqov0.execute-api.ap-south-1.amazonaws.com/formData",
        career
      );

      if (response.status === 200) {
        console.log("Form submitted successfully!");
        setCareer({
          selectionType: "career",
          name: "",
          email: "",
          company: "",
          phone: "",
          role: "",
          resume: "",
          experience: "",
          notRelevant: false,
          PdfBase64: "",
        });
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submitted successfully");
      } else {
        //console.error("Form submission failed.");
        setIsSubmitting(false);
        setIsShown(true);

        setResMsg("Form submission failed.");
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsShown(true);
      console.log(error.message);
      if (error.message == "Network Error")
        return setResMsg("File should be less than 4 MB");

      if (error.message == "Request failed with status code 413")
        return setResMsg("File should be less than 4 MB");
      setResMsg(error.message);
    }
  };

  // PartnerShip From Submit
  const handleSubmitPartnerShip = async (e) => {
    e.preventDefault();
    console.log(formdata);
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://gaysgxqov0.execute-api.ap-south-1.amazonaws.com/formData",
        Partnership
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submitted successfully!");
        setPartnership({
          selectionType: "partnership",
          name: "",
          email: "",
          company: "",
          phone: "",
          description: "",
        });
      } else {
        console.error("Form submission failed.");
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submission failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsSubmitting(false);
      setIsShown(true);
      setResMsg("Form submission failed.");
    }
  };

  // Solution From Submit
  const handleSubmitsolutions = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "https://gaysgxqov0.execute-api.ap-south-1.amazonaws.com/formData",
        solutions
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submitted successfully!");
        setSolution([]);
        setSolutions({
          selectionType: "solution",
          name: "",
          email: "",
          company: "",
          phone: "",
          solution: [],
          description: "",
        });
      } else {
        setIsSubmitting(false);
        setIsShown(true);
        setResMsg("Form submission failed.");
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsShown(true);
      setResMsg("An error occurred");
    }
  };
  const resetForm = () => {
    setFormData("");
    setServices([]);
    setSolutions("");
    setSolution([]);
    setPartnership("");
    setCareer("");
  };
  return (
    <div>
      <div className="main-contact">
        <NavBar />
        <div className="p-[3rem] ">
          <div>
            <Fade bottom>
              <h1 className="text-white text-[65px] font-medium ">
                Get in touch!
              </h1>
            </Fade>
          </div>
          <div className="block lg:flex mt-12">
            <div className="lg:w-[50%]">
              <div className="Contact-box box-shadow ">
                <h3 className="text-[35px] text-center text-gradient font-medium ">
                  Are you looking for ?
                </h3>

                <div>
                  <div>
                    {/* Radio Button Start */}
                    <form action="">
                      <div className=" block sm:flex justify-between mt-10">
                        <label className="radio text-[21px] font-medium text-black">
                          Services
                          <input
                            type="radio"
                            value="services"
                            onChange={handleRadioChange}
                            // onClick={resetForm}
                            checked={formdata.selectionType === "services"}
                            name="radio"
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="radio text-[21px] font-medium  text-black">
                          Partnership
                          <input
                            type="radio"
                            value="partnership"
                            // onClick={resetForm}
                            onChange={handleRadioChange}
                            checked={formdata.selectionType === "partnership"}
                            name="radio"
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="radio text-[21px] font-medium  text-black">
                          Career
                          <input
                            type="radio"
                            value="career"
                            onChange={handleRadioChange}
                            checked={formdata.selectionType === "career"}
                            name="radio"
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="radio text-[21px] font-medium mb-4 text-black">
                          Solutions
                          <input
                            type="radio"
                            value="solution"
                            onChange={handleRadioChange}
                            // onClick={resetForm}
                            checked={formdata.selectionType === "solution"}
                            name="radio"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </form>
                    {/* Radio Button End */}

                    {/* Form Start */}

                    {formType === "services" && (
                      <div>
                        <ServicesForm
                          HandleInputChange={HandleInputChange}
                          formdata={formdata}
                          onSubmit={handleSubmit}
                          setFormData={setFormData}
                          services={services}
                          setServices={setServices}
                          isSubmitting={isSubmitting}
                          isShown={isShown}
                          resMsg={resMsg}
                        />
                      </div>
                    )}

                    {formType === "partnership" && (
                      <div>
                        <PartnerShipForm
                          HandleInputChange={HandlePartInputChange}
                          Partnership={Partnership}
                          onSubmit={handleSubmitPartnerShip}
                          isSubmitting={isSubmitting}
                          isShown={isShown}
                          resMsg={resMsg}
                        />
                      </div>
                    )}

                    {formType === "career" && (
                      <div>
                        <CarrerForm
                          HandleInputChange={HandleCareerInputChange}
                          career={career}
                          onSubmit={handlecareerSubmit}
                          setCareer={setCareer}
                          isSubmitting={isSubmitting}
                          handleCheckboxChange={handleCheckboxChange}
                          isShown={isShown}
                          resMsg={resMsg}
                        />
                      </div>
                    )}
                    {formType === "solution" && (
                      <div>
                        <SolutionForm
                          HandleInputChange={HandleSoluInputChange}
                          solutions={solutions}
                          onSubmit={handleSubmitsolutions}
                          setSolutions={setSolutions}
                          solution={solution}
                          setSolution={setSolution}
                          isSubmitting={isSubmitting}
                          isShown={isShown}
                          resMsg={resMsg}
                        />
                      </div>
                    )}
                    {/* Form End */}
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-[50%] p-[5rem] contact-res">
              <div>
                <Fade bottom>
                  <h3 className="text-[32px] font-semibold  heading-text-gradient">
                    eLogic Engineering Services Private Limited
                  </h3>
                </Fade>
                <div className="flex mt-5">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="text-white text-[25px] pr-3 mt-2"
                  />
                  <Fade bottom>
                    <p className="text-white  text-justify w-[46%] text-[20px] font-light leading-10 tracking-normal">
                      2nd Floor, Ashoka HiTech Chambers, Road No. 2, opp. KBR
                      Park, Banjara Hills, Hyderabad, Telangana 500034
                    </p>
                  </Fade>
                </div>
                <div className="mt-10">
                  <Fade bottom>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15226.953595431843!2d78.4239043!3d17.4243376!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb970d18993739%3A0x90c151e6974d10e8!2seLogicTech%20Edge!5e0!3m2!1sen!2sin!4v1690541333673!5m2!1sen!2sin"
                      className="iframe"
                      loading="lazy"
                    ></iframe>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
