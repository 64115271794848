import React from "react";
import NavBar from "./Layout/NavBar";
import ClientCard from "./ClientCard";
import Fade from "react-reveal/Fade";

const HeroPage = () => {
  return (
    <div className="main-page-container">
      <NavBar />
      <Fade bottom>
        <div className="main-heading-section pr-[3rem] ">
          <h1 className="line">
            Digitizing the <br />
            <b>Physical World</b>
          </h1>
          <p className="!font-normal">
            We are a team of embedded engineers, designers and solutions
            architects enabling IoT connectivity and intelligence capabilities
            for physical assets by designing ground-up electronics, hardware,
            firmware and software.
          </p>
          <a href="/contact">
            <button className="reach-us-button" role="button">
              Reach Us Now
            </button>
          </a>
          {/* <a href="#" className="ml-5 learn-more-link">
          Learn More
        </a> */}
        </div>
      </Fade>
      <hr />
      <div>
        <ClientCard />
      </div>
    </div>
  );
};

export default HeroPage;
