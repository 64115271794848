import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import m1 from "../../../assets/images/Moniter-1.jpg";
import m2 from "../../../assets/images/Moniter-2.jpg";

const OccupancyMonitor = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    // pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <div>
      <div className="smart-parking-res Act-main-container lg:pt-[5rem] lg:pb-[3rem]  pb-16">
        <div>
          <div className="block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="Access-res lg:p-0 sm:p-[4px] heading-text-gradient font-semibold text-[35px] md:text-[42px] lg:text-[50px]  leading-10 lg:leading-[5rem]  mb-3 lg:mb-6">
                  Occupancy Monitoring Solutions
                </h2>
                <p className="pt-2 lg:w-[80%] text-[24px] text-justify text-black font-medium ">
                  Get facility-wide insight into the occupancy and movement of
                  people inside and through spaces, all without compromising
                  occupant privacy
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    See occupant density and derive trends over time
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Observe footfall pattern to optimize placement and location
                    of furniture, materials, signages and entry/exit points
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    See foot traffic patterns of entry and exit points to
                    optimize security and support
                  </li>

                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Optimize energy consumption based on zone occupancy
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <Slider {...settings}>
                  <img src={m1} className="w-[100%] access" />
                  <img src={m2} className="w-[100%] access" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccupancyMonitor;
