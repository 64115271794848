import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutussection from "./pages/AboutUs";
import LandingPage from "./pages/LandingPage";
import "./assets/fonts/stylesheet.css";
import OurTeam from "./pages/OurTeam";
import ContactUs from "./pages/ContactUs";
import SmartBuilding from "./pages/SmartBuilding";
import IIot from "./pages/IIot";
import Mobility from "./pages/Mobility";
import Redirect from "./components/Redirect";
import IoTConnectvityModule from './components/Casestudies/IoTConnectvityModule'
import Wirelessvotingdevice from './components/Casestudies/Wirelessvotingdevice'
import Signalprocessingboard from './components/Casestudies/Signalprocessingboard'
import Batterymanagementsystem from './components/Casestudies/Batterymanagementsystem'
import Powerdistribution from './components/Casestudies/Powerdistribution'
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<Aboutussection />} />
          <Route path="/team" element={<OurTeam />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/solution" element={<Redirect />} />
          <Route path="/smartbuildings" element={<SmartBuilding />} />
          <Route path="/mobility" element={<Mobility />} />
          <Route path="/iiot" element={<IIot />} />
          <Route path="/IoTConnectvityModule" element={<IoTConnectvityModule />} />
           <Route path="/Wirelessvotingdevice" element={<Wirelessvotingdevice />} />
          <Route path="/Signalprocessingboard" element={<Signalprocessingboard />} />
          <Route path="/Batterymanagementsystem" element={<Batterymanagementsystem />} />
          <Route path="/Powerdistribution" element={<Powerdistribution />} />

        </Routes>
      </div>
    </Router>
  );
} 

export default App;
