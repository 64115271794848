import React from "react";
import Fade from "react-reveal/Fade";

const Team = () => {
  return (
    <div className="our-team-res mt-4 grid grid-cols-1 justify-items-center md:grid-cols-2 gap-[3rem] lg:grid-cols-4 xl:grid-cols-4">
      <Fade bottom>
        <div className=" mb-[30px] ">
          <img src="/teamimg/palash.jpg" className="our-image " />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Palash Balani
              </h3>
            </div>
            <p className="text-[16px] text-white">
              Senior Business Development Associate
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/m.sandeep.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Sandeep M
              </h3>
            </div>
            <p className="text-[16px] text-white">
              Hardware Lead
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res ">
          <img src="/teamimg/shiva.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Shiva Srinivas Ch
              </h3>
            </div>
            <p className="text-[16px] text-white">Senior UI/UX Designer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/deepak.bagdi.jpg" className="our-image" alt="team" />
          <div className=" ">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Deepak Bagdi
              </h3>
            </div>
            <p className="text-[16px] text-white">Fullstack Developer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/Shailendra.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Shailendra Kumar
              </h3>
            </div>
            <p className="text-[16px] text-white">Business Development Associate</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/kvn.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                KVN Singh
              </h3>
            </div>
            <p className="text-[16px] text-white">Embedded Lead</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/varsha01.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Varsha Devi K
              </h3>
            </div>
            <p className="text-[16px] text-white">Corporate HR Manager</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img
            src="/teamimg/chandarsekhar01.png"
            className="our-image"
            alt="team"
          />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Chandra Shekhar Modi
              </h3>
            </div>
            <p className="text-[16px] text-white">PCB Design Engineer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/naveen.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Naveen N
              </h3>
            </div>
            <p className="text-[16px] text-white">Embedded Hardware Engineer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/saikiran.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Sai Kiran N
              </h3>
            </div>
            <p className="text-[16px] text-white">Embedded Hardware Engineer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/srikanth.s.jpg" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Srikanth S
              </h3>
            </div>
            <p className="text-[16px] text-white">Backend Developer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/sandeep.k.jpg" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Sandeep K
              </h3>
            </div>
            <p className="text-[16px] text-white">Mechnical Designer enginner </p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/t.sandeep.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Sandeep T
              </h3>
            </div>
            <p className="text-[16px] text-white">Procurement and Store Manager</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/manasa.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Manasa T
              </h3>
            </div>
            <p className="text-[16px] text-white">Sr. PCB Design Engineer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/manogna.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Manogna Vangipuram
              </h3>
            </div>
            <p className="text-[16px] text-white">Embedded Firmware Engineer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/aishvarya.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Aishwarya U
              </h3>
            </div>
            <p className="text-[16px] text-white">Embedded Firmware Engineer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/pranay01.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Pranay V
              </h3>
            </div>
            <p className="text-[16px] text-white">Frontend Developer</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/aditya.iot.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Adityan S
              </h3>
            </div>
            <p className="text-[16px] text-white">IoT Application Enginner</p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/anurag01.jpg" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Anurag Pandey
              </h3>
            </div>
            <p className="text-[16px] text-white">Embedded Firmware Engineer</p>
          </div>
        </div>
      </Fade>

      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/murali.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Kola Murali
              </h3>
            </div>
            <p className="text-[16px] text-white">Sr. Hardware Design Enginner</p>
          </div>
        </div>
      </Fade>

      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/suman.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Suman
              </h3>
            </div>
            <p className="text-[16px] text-white">Manager IT</p>
          </div>
        </div>
      </Fade>
      
      <Fade bottom>
        <div className=" mb-[30px] join-img-res">
          <img src="/teamimg/hemanth.png" className="our-image" alt="team" />
          <div className="ms-1">
            <div className="flex">
              <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                Hemanth
              </h3>
            </div>
            <p className="text-[16px] text-white">Accounts Manager</p>
          </div>
        </div>
      </Fade>


      {/* <div className=" mb-[30px] join-img-res">
        <img src="/teamimg/" className="our-image" alt="team" />
        <div className="ms-1">
          <div className="flex">
            <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
              
            </h3>
          </div>
          <p className="text-[16px] text-white"></p>
        </div>
      </div> */}
    </div>
  );
};

export default Team;
