import React from "react";
import Footer from "../components/Layout/Footer";
import Testimonials from "../components/Testimonials";
import Marquee from "../components/Marquee";
import OurServices from "./OurServices";
import OurSolutions from "./OurSolutions";
import HeroPage from "../components/HeroPage";
import OurApproach from "../components/OurApproach";
import GTagScript from '../components/GTagScript'; 

function LandingPage() {
  return (
    <div>
      <div>
      <GTagScript />
        <HeroPage />
        <Marquee />
      </div>

      <OurApproach />

      <OurSolutions />
      <OurServices />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default LandingPage;
