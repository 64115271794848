import React from "react";
import Smart1 from "../../../assets/images/Smart-1.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EvManagement = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-2 items-center justify-items-center">
        <div className="xl:pl-[3rem]">
          <div className="lg:pt-11">
            <h2 className="text-[#07D4FA]  font-medium text-[35px]  md:text-[42px]  lg:text-[50px]  leading-10 lg:leading-[5rem]  ">
              Parking & EV Management
            </h2>

            <ul className="pt-5 text-black text-[18px] font">
              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Select custom layout views across blocks/floors of a parking facility
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  See the current occupancy of individual bays in a custom parking layout
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  See a real-time activity log of all vehicles entering and exiting the premises, as well as occupying a specific parking bay.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Integrate digital payment gateways and track transactions and monetization metrics.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Monitor time-based energy consumption trends in EV charging to identify peak load times
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Snapshot of current parking bay occupancy across floors
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="connect-image-conatiner lg:pt-[2rem]">
          <img src={Smart1} alt="" className="ev-1" />
        </div>
      </div>
    </div>
  );
};

export default EvManagement;
