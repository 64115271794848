import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Solutions from "../components/OurSolutions/Solutions";
import Smart from "../components/OurSolutions/Smart";
import Mobilitys from "../components/OurSolutions/Mobilitys";
import Fade from "react-reveal/Fade";

const OurSolutions = () => {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-out",
    });

    window.addEventListener("wheel", handleMouseWheel);

    return () => {
      window.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  const handleMouseWheel = (event) => {
    const deltaY = event.deltaY;
    const animationDuration = 800;
    if (deltaY > 0) {
      AOS.refreshHard();
      AOS.refresh();
      AOS.refreshHard({
        duration: animationDuration,
        easing: "ease-in-out",
      });
    } else if (deltaY < 0) {
      AOS.refreshHard();
      AOS.refresh();
      AOS.refreshHard({
        duration: animationDuration,
        easing: "ease-in-out",
        reverse: true,
      });
    }

    // Show images when they come into the viewport
    const images = document.querySelectorAll(".image-scroll");
    const headings = document.querySelectorAll(".gardient-text");
    let visibleIndex = -1;

    images.forEach((image, index) => {
      if (isElementInViewport(image)) {
        visibleIndex = index;
        image.classList.add("show-image");
      } else {
        image.classList.remove("show-image");
      }
    });
  };

  const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  const [solution, setSolution] = useState("iiot");

  const handleChange = (event) => {
    setSolution(event.target.id);
  };
  return (
    <>
      <div className="our-solutions-container text-xl leading-8">
        <div className=" solutions pb-10 ">
          <Fade bottom>
            <h1 className="font-medium text-[#07D4FA] lg:pt-[4rem] lg:pb-[4rem] text-[48px] p-[3rem] ">
              Industries Served
            </h1>
          </Fade>
          <Fade bottom>
            <div className="soulbutton grid md:grid-cols-3 grid-cols-1 ss:grid-cols-3 gap-4 px-10 py-5 justify-between m-auto overflow-hidden max-w-7xl">
              <button
                onClick={handleChange}
                id="iiot"
                className={`${
                  solution === "iiot" ? "activee" : ""
                } font-medium lg:text-3xl px-14 text-black py-5`}
              >
                Industrial IoT
              </button>

              <button
                onClick={handleChange}
                id="smart"
                className={`${
                  solution === "smart" ? "activee" : ""
                } font-medium lg:text-3xl px-14 text-black py-5`}
              >
                Smart Building
              </button>
              <button
                onClick={handleChange}
                id="mobility"
                className={`${
                  solution === "mobility" ? "activee" : ""
                } font-medium lg:text-3xl px-14 text-black py-5`}
              >
                Mobility
              </button>
            </div>
          </Fade>

          {solution === "iiot" && (
            <Fade bottom>
              <div>
                <Solutions />
              </div>
            </Fade>
          )}
          {solution === "smart" && (
            <Fade bottom>
              <div>
                <Smart />
              </div>
            </Fade>
          )}
          {solution === "mobility" && (
            <Fade bottom>
              <div>
                <Mobilitys />
              </div>
            </Fade>
          )}

          <div className="text-end pr-[3rem] text-[17px]">
            {solution === "iiot" && (
              <a href="/iiot">
                <u>know more</u>
              </a>
            )}
            {solution === "smart" && (
              <a href="/solution">
                <u>know more</u>
              </a>
            )}
            {solution === "mobility" && (
              <a href="/mobility">
                <u>know more</u>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurSolutions;
