import React from "react";
import Act1 from "../../../assets/images/Act/Act.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Act = () => {
  return (
    <div className="grid lg:grid-cols-2 items-center justify-items-center">
      <div className="md:pt-[5rem]  xl:pl-[3rem]">
        <div className="">
          <h2 className="text-[#07D4FA] font-medium text-[50px] mb-3 lg:mb-6">
            Act
          </h2>
          <ul className="pt-5 text-black text-[18px] font">
            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                Create rules based on machine parameters or time-based
                schedules.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Notify people, trigger alarms and modify control parameters
                based on the rules you create.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                One stop access to the major functions of the
                portal.Customizable to suit application requirements.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Pin your most important control parameters here and modify their
                values.
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div className="connect-image-conatiner">
        <img src={Act1} alt="" className="lg:mt-10 Act-img" />
      </div>
    </div>
  );
};

export default Act;
