import React from "react";
import NavBar from "../../Layout/NavBar";
import Fade from "react-reveal/Fade";

const IIotHero = () => {
  return (
    <div>
      <div className="w-full our-iiot-container">
        <NavBar />
        <div>
          <Fade bottom>
            <h1 className=" text-center text-white text-[50px] sm:text-[70px] font-semibold py-[5%] ">
              Industrial IoT Solutions
            </h1>
          </Fade>
        </div>
      </div>
      <div className="Elevate">
        <Fade bottom>
          <div>
            <h2 className="pl-0 heading-text-gradient font-medium text-[40px] mb-3">
              Off shore monitoring & control of physical assets
            </h2>
            <p className="text-[18px] leading-8 text-justify mt-4">
              We build tailor made Industrial IoT Solutions to provide you with
              the best strategy for equipping your asset heavy areas like
              factory floor, machine shops etc. Our hardware agnostic approach
              enables us to communicate with any kind of existing process
              control and automation system or standalone machines to bring you
              all the data you need to monitor, control and automate your
              existing operations.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default IIotHero;
