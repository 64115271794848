import React, { useEffect } from 'react'
// import { useHistory } from 'react-router';

const Redirect = () => {
    // const history = useHistory();

    useEffect(() => {
        window.location.href = '/smartbuildings'
    }, [])
    
    return (
        <div></div>
    )
}

export default Redirect