import React from "react";
import Fade from "react-reveal/Fade";

const JoinOurTeam = () => {
  return (
    <div className="join-team flex px-[3rem] ">
      <div className=" pt-10 lg:p-[4rem] lg:px-0">
        <Fade bottom>
          <h4 className="text-white lg:text-[25px]">
            Looking for a New Adventure ?
          </h4>
        </Fade>
        <Fade bottom>
          <h2 className="text-white text-[56px] join-text mt-2 font-semibold">
            Join Our Team and Explore Boundaries
          </h2>
        </Fade>

        <a href="/career">
          <Fade bottom>
            <button className="join-us-button mt-6 lg:mt-7 mb-10" role="button">
              View job openings
            </button>
          </Fade>
        </a>
      </div>
    </div>
  );
};

export default JoinOurTeam;
