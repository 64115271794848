import React from "react";
import "./index.css";
import Fade from "react-reveal/Fade";
const OurCulture = () => {
  return (
    <>
      {/* ******** our-culture *********/}
      <div className="our-culture">
        <div className="p-[3rem]">
          <Fade bottom>
            <h2 className="heading-text-gradient font-medium text-[40px] mb-4">
              Our culture and Values
            </h2>
          </Fade>
          <Fade bottom>
            <p className="text-[17px] leading-8 text-justify">
              At elogictech Edge, We Come Together Every Day Looking Forward To
              The Next Hard Thing. Complex Challenges With Ambiguous Constraints
              Force Us Out Of Our Lanes, And This Is Where We Thrive As
              Engineers, Solutions Architects And Problem-Solvers. When We Are
              Forced Out Of Our Lanes, We Are Forced To Get Creative,
              Resourceful And Experimentative, And That Is The Space Where Great
              Solutions Are Born.
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div className=" grid md:flex gap-24 mt-0 mb-0 mr-[3rem] ml-[3rem]  justify-center items-center">
            <span className="aptitude">Attitude > Aptitude</span>
            <span className="aptitude">Willing To Learn> Experience</span>
            <span className="aptitude">Making Mistakes> Playing With safe</span>
          </div>
        </Fade>

        <div>
          <div className="wow animate__animated animate__fadeInUp  block md:flex  items-center mt-[4em] md:p-8 pb-1">
            <div className="md:w-[25%] mb-8">
              <Fade bottom>
                <div className="icon-1">
                  <img
                    src="https://res.cloudinary.com/srikanth143/image/upload/v1687000516/val_icon_01_ljybrw.svg"
                    alt="icon1  "
                    className="mb-[1.8em] m-[auto]"
                  />
                  <div className="text-center text-[16px] leading-7">
                    <p>Find Elegant Solutions</p>
                    <p>To Odd Problems</p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="md:w-[25%] mb-8">
              <Fade bottom>
                <div className="icon-2">
                  <img
                    src="https://res.cloudinary.com/srikanth143/image/upload/v1687000516/val_icon_02_qdku3b.svg"
                    alt="icon1  "
                    className="mb-[1.8em] m-[auto] "
                  />
                  <div className="text-center text-[16px] leading-7">
                    <p>A Project Isn’t Done Until It Has</p>
                    <p>Created Real Value</p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="md:w-[25%] mb-8">
              <Fade bottom>
                <div className="icon-3">
                  <img
                    src="https://res.cloudinary.com/srikanth143/image/upload/v1687000516/val_icon_03_xodowk.svg"
                    alt="icon1  "
                    className="mb-[1.8em] m-[auto] "
                  />
                  <div className="text-center text-[16px] leading-7">
                    <p>Have Fun With The Work </p>
                    <p> While Doing It</p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="md:w-[24%] mb-8">
              <Fade bottom>
                <div className="icon-4">
                  <img
                    src="https://res.cloudinary.com/srikanth143/image/upload/v1687000516/val_icon_04_kloph4.svg"
                    className="mb-[1.8em] m-[auto]"
                  />
                  <div className="text-center text-[16px] leading-7">
                    <p>Work For The Vision, </p>
                    <p> Not The Boss</p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCulture;
