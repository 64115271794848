import React from "react";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
import "./index.css";
import votingsystemimg from "../../assets/Casestudiesimgs/votingsystemimg.png";
const Wirelessvotingdevice = () => {
  return (
    <div className="">
      <div className="modulecontainer">
        <NavBar />
        <h2 class="text-center sm:text-7xl sm:text-center text-6xl title-font font-semibold text-white mt-4 mb-4 leading-relaxed">
          WIRELESS VOTING DEVICE
        </h2>
      </div>
      <div className="clientbgcontainer">
        <section class="text-gray-600 body-font overflow-hidden">
          <div class="lg:px-14 py-24 mx-auto">
            <div class="flex flex-wrap ">
              <div class="p-12 lg:w-1/2 flex flex-col items-start ">
                <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                  The client's challenge
                </h2>
                <p class="leading-10 mb-8 text-white text-[20px]">
                  Lorem Ipsum Is Simply Dummy Text Of The Printing. Lorem Ipsum
                  Is Simply Dummy Text Of The Printing. Lorem Ipsum Is Simply
                  Dummy Text Of The Printing. Lorem Ipsumis Simply Dummy Text Of
                  The Printing.
                </p>
                <div className="grid grid-cols-2 gap-2 mb-8">
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border">Parameterss Captured</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border">Communication protocols</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border ">Environmental requirements</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border ">Power and bandwidth requirements</div></div>
                </div>
                <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc ">
                    An ad-hoc wireless system to support communication with
                    up-to 10,000 nodes.
                  </li>
                  <li className="ml-6 list-disc ">
                    Battery life of up-to 2 Years.
                  </li>
                  <li className="ml-6 list-disc ">
                    Low cost and compact form factor.
                  </li>
                </ul>
                
                <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                  Our Solution  
                </h2>

                <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc">
                    Robust Data Integrity across 10,000 nodes communicating
                    parallelly.
                  </li>
                  <li className="ml-6 list-disc ">
                    Robust Data Integrity across 10,000 nodes communicating
                    parallelly.
                  </li>
                  <li className="ml-6 list-disc ">
                    Spread spectrum techniques for minimal interference.
                  </li>
                  </ul>
                  <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                    Benefits 
                </h2>
                <ul class=" text-white leading-10 mb-8 text-[20px]">

                  <li className="ml-6 list-disc ">
                    Low BoM cost for high volumes.
                  </li>
                  <li className="ml-6 list-disc ">
                    RF Communication on ISM band with custom Antenna design
                  </li>
                  <li className="ml-6 list-disc ">
                    Multi-Frequency Communication architecture
                  </li>
                  <li className="ml-6 list-disc ">
                    LoRa modulation for low power and long range.
                  </li>
                </ul>
              </div>

              <div class="p-12 md:w-1/2 flex flex-col items-start">
                <img alt="blog" src={votingsystemimg}  className="w-full"  />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Wirelessvotingdevice;
