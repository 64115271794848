import React from "react";
import measure from "../../../assets/images/Measure/Mesure.png";

import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Measure = () => {
  return (
    <div className="grid lg:grid-cols-2 items-center justify-items-center">
      <div className="pt-2 xl:pl-[3rem]">
        <div className="md:pt-[5rem]">
          <h2 className="text-[#07D4FA] font-medium text-[50px] mb-3 lg:mb-6">
            Measure
          </h2>
          <ul className="pt-5 text-black text-[18px] font">
            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                View real time data of your critical machines categorized by
                zone and area.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Users can pin the most important ones to their respective
                dashboards.
              </li>
            </div>

            <div className="flex mb-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-[#2E9EE0] pr-3 mt-2"
              />
              <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                Customize specific data associated with each parameter.
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div className="connect-image-conatiner lg:pt-[7rem]">
        <img src={measure} alt="" className="Mesure-img" />
      </div>
    </div>
  );
};

export default Measure;
