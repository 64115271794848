import React from "react";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
import "./index.css";
import batterymngmt from "../../assets/Casestudiesimgs/batterymngmt.png";
const Batterymanagementsystem = () => {
  return (
    <div className="">
      <div className="modulecontainer">
        <NavBar />
        <h2 class="text-center sm:text-7xl sm:text-center text-6xl title-font font-semibold text-white mt-4 mb-4 leading-relaxed">
        BATTERY MANAGEMENT SYSTEM
        </h2>
      </div>
      <div className="clientbgcontainer">
        <section class="text-gray-600 body-font overflow-hidden">
          <div class="lg:px-14 py-24 mx-auto">
            <div class="flex flex-wrap ">
              <div class="p-12 lg:w-1/2 flex flex-col items-start ">
                <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                  The client's challenge
                </h2>
                <p class="leading-10 mb-8 text-white text-[20px]">
                  Lorem Ipsum Is Simply Dummy Text Of The Printing. Lorem Ipsum
                  Is Simply Dummy Text Of The Printing. Lorem Ipsum Is Simply
                  Dummy Text Of The Printing. Lorem Ipsumis Simply Dummy Text Of
                  The Printing.
                </p>
                <div className="grid grid-cols-2 gap-2 mb-8">
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border">Parameterss Captured</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border">Communication protocols</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border ">Environmental requirements</div></div>
                    <div><div className="py-2 px-4 text-left m-2 text-[16px] w-fit text-white radiant-border ">Power and bandwidth requirements</div></div>
                </div>
                <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                  Our Solutions
                </h2>
                <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc">
                  Active cell balancing
                  </li>
                  <li className="ml-6 list-disc ">
                  GUI with fault alerts and real-time health metrics
                  </li>
                  <li className="ml-6 list-disc ">IoT enablement for remote monitoring</li>
                  <li className="ml-6 list-disc ">
                  High degree of configurability for all ranges of battery capacities, current ratings and voltages
                  </li>
                  </ul>
                <h2 class="text-transparent bg-gradient-to-r from-violet-400  via-sky-600 to-cyan-400 bg-clip-text font-medium">
                    Benefits
                </h2>
                 <ul class=" text-white leading-10 mb-8 text-[20px]">
                  <li className="ml-6 list-disc">
                  Active cell balancing
                  </li>
                  <li className="ml-6 list-disc ">
                  GUI with fault alerts and real-time health metrics
                  </li>
                  <li className="ml-6 list-disc ">IoT enablement for remote monitoring</li>
                  <li className="ml-6 list-disc ">
                  High degree of configurability for all ranges of battery capacities, current ratings and voltages
                  </li>
                  <li className="ml-6 list-disc ">
                  Made in India: contributing to battery industry indigenization goals
                  </li>
                  <li className="ml-6 list-disc ">
                  Continuous improvement to meet evolving regulatory requirements
                  </li>
                </ul>
              </div>

              <div class="p-12 md:w-1/2 flex flex-col items-start">
                <img alt="blog" src={batterymngmt}  className="w-full"  />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Batterymanagementsystem;
