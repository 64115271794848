import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iiotConnect from "../../../assets/images/iiotConnect.png";
import iiotConnect2 from "../../../assets/images/ConnectIot-2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ConnectIiot = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    // pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <div>
      <div className="fac-2 smart-main-container lg:pt-[3rem] lg:pb-[3rem] pb-16">
        <div>
          <div className="block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="text-[48px] heading-text-gradient  mb-5 font-semibold ">
                  Connect
                </h2>
                <p className="pt-2 lg:w-[80%]  text-[24px] text-justify text-black font-medium ">
                  Seamlessly connect with your physical assets using our
                  universal protocol adapters and plugins.
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    View available devices and add them to different zones.
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Look at the configuration of your existing devices in a zone and modify them when required.
                  </li>

                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Customize your operational area into areas and zones.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <Slider {...settings}>
                  <img src={iiotConnect} className="w-[100%] access" />
                  <img src={iiotConnect2} className="w-[100%] access" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectIiot;
