import React from "react";
import Button from "../Common/Button";
import Fade from "react-reveal/Fade";

const MeetsEfficiency = () => {
  return (
    <div>
      <div className="meets-main-container pt-[3rem] pb-[3rem]  text-center">
        <Fade bottom>
          <h2 className="text-[30px] lg:text-[45px] font-medium pr-[25%] pl-[25%] ">
            Multiply you revenue by changing the way you operate facilities.
          </h2>
        </Fade>
        <Fade bottom>
          <div className="mt-5">
            <Button />
            <a href="/about" className="ms-5 text-white">
              <u>learn more</u>
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default MeetsEfficiency;
