import React from "react";
import "./index.css";
import NumberCounter from "number-counter";
import NavBar from "../../Layout/NavBar";
import Fade from "react-reveal/Fade";

const WhoAreWe = () => {
  return (
    <>
      {/* ****** Who Are We Start ****** */}

      <div className="w-full main-container">
        <NavBar />
        <div className="flex text-center flex-col  ">
          <div className="p-[4.6%] about-res">
            <Fade bottom>
              <h1 className="text-gradient text-[70px] font-semibold">
                {" "}
                About Us
              </h1>
            </Fade>

            <a href="/career">
              <Fade bottom>
                <button
                  className="join-us-button  m-[auto] mt-[1rem] md:mt-[2rem]"
                  role="button"
                >
                  Join our Team
                </button>
              </Fade>
            </a>
          </div>
        </div>
      </div>

      <div className="who-are-we">
        <Fade bottom>
          <h2 className="pl-0 heading-text-gradient font-medium text-[40px] mb-3">
            Who we are
          </h2>
          <p className="text-[17px] leading-7 text-justify">
            {" "}
            We Are A Team Of Engineers, Designers And Solutions Architects Who
            Aim To Tackle Challenges And Develop Solutions That Don't Exist Yet.
            We Provide Design, Engineering, Development, Testing And Validation
            Services To Innovators In The Consumer Robotics, Electronics, Iot
            And Automation Spaces.
          </p>
        </Fade>
      </div>

      <div className="block md:flex justify-around gap-[1rem] image-icons text-center ">
        <div className="lg:mb-0 md:pb-0 sm:pb-10 ss:pb-10 icon">
          <img
            src="https://res.cloudinary.com/srikanth143/image/upload/v1686981627/globe_2_kbhp5n.svg"
            alt="globe"
            className="m-[auto] mb-3 globe"
          />
          <span className="text-[50px] font-semibold text-white">
            <NumberCounter end={4} start={0} delay="2" />
          </span>
          <p className=" text-white font-medium md:text-[14px] lg:md:text-[14px] lg:text-[20px]">
            Worldwide Presence
          </p>
        </div>
        <div className="lg:mb-0 md:pb-0 sm:pb-10 ss:pb-10 icon">
          <img
            src="https://res.cloudinary.com/srikanth143/image/upload/v1686981626/rocket_ofod4l.svg"
            alt="globe"
            className="m-[auto] mb-3 rocket"
          />
          <span className="text-[50px] font-semibold text-white">
            <NumberCounter end={10} start={0} delay="2" />
          </span>
          <p className="text-white font-medium md:text-[14px] lg:md:text-[14px] lg:text-[20px]">
            Successful Deployments
          </p>
        </div>
        <div className="lg:mb-0 md:pb-0 sm:pb-10 ss:pb-10 icon">
          <img
            src="https://res.cloudinary.com/srikanth143/image/upload/v1686981626/user_2_lgqebe.svg"
            alt="globe"
            className="m-[auto] mb-3 client"
          />
          <span className="text-[50px] font-semibold text-white">
            <NumberCounter end={5} start={0} delay="3" />
          </span>
          <p className="text-white font-medium md:text-[14px] lg:text-[20px]">
            Diverse Clients
          </p>
        </div>
        <div className="lg:mb-0  year">
          <img
            src="https://res.cloudinary.com/srikanth143/image/upload/v1686981626/cpu_tvawfc.svg"
            alt="globe"
            className="m-[auto] mb-3 yers"
          />
          <span className="text-[50px] font-semibold text-white">
            <NumberCounter end={20} start={0} delay="4" preFix="+" />
          </span>
          <p className="text-white font-medium md:text-[14px] lg:text-[20px]">
            Years Of Industry Experience
          </p>
        </div>
      </div>
      <div className=" block lg:flex justify-items-center">
        <div className=" w-full lg:w-[50%] mission-container ">
          <div className=" lg:m-[120px] ">
            <Fade bottom>
              <h2 className="mission-heading font-medium text-[48px] mb-1">
                Missons
              </h2>
            </Fade>
            <Fade bottom>
              <p className="text-[20px] 2xl:w-[60%] text-black font-normal leading-8 text-justify">
                To Be A One-Stop-Shop Expertise & Service Partner To Emerging
                Tech Creators And Product Developers Looking To Take Innovative
                Concepts To Fruition And Maturity.
              </p>
            </Fade>
          </div>
        </div>
        <div className="w-full lg:w-[50%] vision-container">
          <div className="lg:m-[120px]">
            <Fade bottom>
              <h2 className="heading-text-gradient font-medium text-[48px] mb-1 ">
                Vision
              </h2>
            </Fade>
            <Fade bottom>
              <p className="text-[20px] 2xl:w-[70%]  text-black font-normal leading-8 text-justify">
                A World Where Technology Creators And Innovators Have Ready
                Access To Skills, Expertise And Support To Solve Engineering And
                Technology Challenges At A Pace That Keeps Up With Constantly
                Evolving Business Goals.
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoAreWe;
