import React from "react";

const Button = () => {
  return (
    <a href="/contact">
      <button className="reach-us-button" role="button">
        Reach Us Now
      </button>
    </a>
  );
};

export default Button;
