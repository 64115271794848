import React from "react";
import OurManagement from "../components/OurTeam/OurManagement";
import HeroPageOurTeam from "../components/OurTeam/HeroPageOurTeam";
import Team from "../components/OurTeam/OurTeam";
import JoinOurTeam from "../components/OurTeam/JoinOurTeam";
import Footer from "../components/Layout/Footer";

const OurTeam = () => {
  return (
    <div>
      <HeroPageOurTeam />
      <OurManagement />
      <JoinOurTeam />
      <Footer />
    </div>
  );
};

export default OurTeam;
