import React from "react";

const ServicesForm = ({
  HandleInputChange,
  formdata,
  setFormData,
  onSubmit,
  services,
  setServices,
  isSubmitting,
  isShown,
  resMsg,
}) => {
  const handleServiceClick = (s) => {
    console.log(s);
    const updatedServices = services.includes(s)
      ? services.filter((item) => item !== s)
      : [...services, s];

    setServices(updatedServices);
    setFormData((prevData) => ({
      ...prevData,
      services: updatedServices,
    }));
  };
  const service = [
    "Embedded Hardware Design",
    "Embedded Software Development",
    "Wireless Systems Development",
    "User Interface Development",
    "Technology Consulting",
  ];
  return (
    <div>
      {/* USer Details Start */}
      <form action="" onSubmit={onSubmit}>
        <div className="flex-wrap justify-between block w-full mt-6 user-details lg:flex">
          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Name<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={formdata.name}
              onChange={HandleInputChange}
              placeholder="Please enter your full name"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Company
            </span>
            <input
              autoComplete="off"
              type="text"
              name="company"
              value={formdata.company}
              onChange={HandleInputChange}
              placeholder="Please enter company name"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              E-mail
            </span>
            <input
              autoComplete="off"
              type="email"
              name="email"
              value={formdata.email}
              onChange={HandleInputChange}
              placeholder="Please enter your E-Mail ID"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Phone
            </span>
            <input
              autoComplete="off"
              type="tel"
              name="phone"
              value={formdata.phone}
              onChange={HandleInputChange}
              placeholder="Please enter your valid phone number"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>
        </div>

        {/* Select Services */}
        <div>
          <h3 className="text-[24px] font-semibold text-black ">
            Choose a service
          </h3>
          <div className="flex-wrap block mt-2 md:flex">
            {service.map((s, index) => (
              <div
                key={index}
                className={`border-2 border-black cursor-pointer px-3 py-3  mr-3 mt-5 ${
                  services.includes(s) ? "select" : ""
                }`}
                onClick={() => handleServiceClick(s)}
              >
                <p className=" font-medium text-[13px] ">{s}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-9">
          <textarea
            cols="3"
            rows="5"
            name="description"
            value={formdata.description}
            onChange={HandleInputChange}
            placeholder="Tell us about your project"
            className="text-[10px] md:text-[14px] italic formInputText"
          ></textarea>
        </div>
        {/* Submit Button */}
        {isShown && <div className="text-red-500 font-medium">{resMsg}</div>}

        <div className="mt-10 text-center">
          {isSubmitting ? (
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <button type="submit" className="submit-button">
              Submit
            </button>
          )}
        </div>
      </form>
      {/* USer Details End */}
    </div>
  );
};

export default ServicesForm;
