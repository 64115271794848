import React from "react";
import ScrollSlider from "./IIoTSlider/Carousel";
import Connect from "./IIoTSlider/Connect";
import Measure from "./IIoTSlider/Measure";
import Analyze from "./IIoTSlider/Analyze";
import Act from "./IIoTSlider/Act";

const Solutions = () => {
  return (
    <div>
      <div className="mt-4">
        <div className="lg:block hidden">
          <ScrollSlider />
        </div>

        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[850px]  ">
          <Connect />
        </div>
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[700px]   ">
          <Measure />
        </div>
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[700px]   ">
          <Analyze />
        </div>
        <div className="lg:hidden xl:hidden p-[3rem]  bg-[#dffffc] lg:h-[1060px] sm:h-[890px]  ">
          <Act />
        </div>
      </div>
    </div>
  );
};

export default Solutions;
