import React from "react";
import Contact from "../components/ContactUs/Contact";
import Footer from "../components/Layout/Footer";

const ContactUs = () => {
  return (
    <div>
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactUs;
