import React, { useEffect } from 'react';

const GTagScript = () => {
  useEffect(() => {
    const GA_MEASUREMENT_ID = 'G-2GGTTC8R3P';  

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', GA_MEASUREMENT_ID);
    };
  }, []);

  return null;
};

export default GTagScript;
