import React from "react";
import IIotHero from "../components/OurSolutions/IIOT/IIotHero";
import MeetsEfficiency from "../components/OurSolutions/MeetsEfficiencyNew";
import Footer from "../components/Layout/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IIoTCard from "../components/OurSolutions/IIoTCard";

const IIot = () => {
  return (
    <>
      <div>
        <IIotHero />
        {/* <div className="lg:hidden block">
          <ConnectIiot />

          <div className="mt-3">
            <MeasureIiot />
          </div>
          <div className="mt-3">
            <AnalyzeIiot />
          </div>
          <div className="mt-3 mb-3">
            <ActIiot />
          </div>
        </div> */}
        <IIoTCard />
        <MeetsEfficiency />
        <Footer />
      </div>
    </>
  );
};

export default IIot;
