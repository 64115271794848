import React, { useEffect, useState } from "react";
import consumerElectroincs from "../assets/images/service_bnr_img-min.jpg";
import industrialRobot from "../assets/images/automation manufactures.jpg";
import serviceBnr3 from "../assets/images/service_bnr_3.jpg";
import homeAutomation from "../assets/images/home-automation-interest.jpg";
import automobile from "../assets/images/assembly-line-production-new-car-automated-welding-car-body-production-line-robotic-arm-car-production-line-is-working.jpg";
import robotics from "../assets/images/robotics _inventors.jpg";
import Fade from "react-reveal/Fade";

function ServiceBanner() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [serviceItems] = useState([
    {
      title: "Electronics/durables",
      description:
        "Consumer electronics development requires a high degree of engineering effort to implement communication.",
      image: consumerElectroincs,
    },
    {
      title: "Industrial automation",
      description:
        "Industrial automation involves a deep domain understanding of processes, constraints and optimization goals. Our team will work to develop a system-level understanding.",
      image: industrialRobot,
    },
    {
      title: "Instrumentation",
      description:
        "Instrumentation engineering requires development efforts in a resource-constrained environment. Enabling accurate and robust functionality in such environments requires. ",
      image: serviceBnr3,
    },
    {
      title: "Smart Buildings",
      description:
        "With the boom in the home automation and IoT space, engineers and developers need to rely on increasingly sophisticated and cost-effective solutions to stand out and position.",
      image: homeAutomation,
    },
    {
      title: "Automobile",
      description:
        "The rapid growth in the adoption of EV in the automotive industry has propelled the need for a multitude of electronic subsystems that communicate with various vehicular functions.",
      image: automobile,
    },
    {
      title: "Robotics",
      description:
        "Robotics development involves the complex integration of instrumentation and sensor systems, processing and control systems as well as sophisticated electromechanical actuation. ",
      image: robotics,
    },
  ]);
  const [industries] = useState([
    {
      title: "Electronics/durables",
      description:
        "Consumer electronics development requires a high degree of engineering effort to implement communication.",
      image: consumerElectroincs,
    },
    {
      title: "Industrial automation",
      description:
        "Industrial automation involves a deep domain understanding of processes, constraints and optimization goals. Our team will work to develop a system-level understanding.",
      image: industrialRobot,
    },
    {
      title: "Instrumentation",
      description:
        "Instrumentation engineering requires development efforts in a resource-constrained environment. Enabling accurate and robust functionality in such environments requires. ",
      image: serviceBnr3,
    },
    {
      title: "Smart Buildings",
      description:
        "With the boom in the home automation and IoT space, engineers and developers need to rely on increasingly sophisticated and cost-effective solutions to stand out and position.",
      image: homeAutomation,
    },
    {
      title: "Automobile",
      description:
        "The rapid growth in the adoption of EV in the automotive industry has propelled the need for a multitude of electronic subsystems that communicate with various vehicular functions.",
      image: automobile,
    },
    {
      title: "Robotics",
      description:
        "Robotics development involves the complex integration of instrumentation and sensor systems, processing and control systems as well as sophisticated electromechanical actuation. ",
      image: robotics,
    },
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % serviceItems.length);
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [serviceItems.length]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {/* Desktop View */}
      <div className="hidden lg:block pb-5 section-wrap mission_vission_wrap wf-section">
        <div className="grid grid-cols-2 items-center">
          <Fade bottom>
            <div>
              <img
                className="serve-bnr-img"
                src={serviceItems[activeIndex].image}
                alt="Service Banner"
              />
            </div>
          </Fade>
          <div>
            <Fade bottom>
              <div className="servebnr-serve-bnr-rt">
                <div className="serv_bnr_list_wrap">
                  {serviceItems.map((item, index) => (
                    <div
                      className={`service-bnr-item${
                        index === activeIndex ? " active" : ""
                      }`}
                      key={index}
                    >
                      <h2
                        className={`srv-bnr-item-head${
                          index === activeIndex ? " active-title" : ""
                        }`}
                        onClick={() => handleItemClick(index)}
                      >
                        {item.title}
                      </h2>
                      {index === activeIndex && (
                        <div className="srv-bnr-desc">{item.description}</div>
                      )}
                      <div className="percentage-bar"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {/* Responsive View */}
      <div className="lg:hidden grid p-1 pt-0 md:p-[3rem] md:pt-0 ">
        {industries.map((industrie, index) => (
          <div className="industries" key={index}>
            <h2 className="text-black text-[30px] md:text-[35px] md:px-2  font-medium py-2">
              {industrie.title}
            </h2>
            <p className="text-[15px] md:text-[20px] md:px-2 md:pb-4 text-justify pb-3 leading-8">
              {industrie.description}
            </p>
            <img src={industrie.image} alt="Service Banner" />
          </div>
        ))}
      </div>
    </>
  );
}

export default ServiceBanner;
