import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Act1 from "../../../assets/images/ActIoT-1.jpg";
import Act2 from "../../../assets/images/ActIoT-2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ActIiot = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    // pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <div>
      <div className="fac Act-main-container lg:pt-[3rem] lg:pb-[3rem] pb-16">
        <div>
          <div className="block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="text-[46px] heading-text-gradient  mb-5 font-semibold">
                  Act
                </h2>
                <p className="pt-2 lg:w-[80%] text-[24px] text-justify text-black font-medium ">
                  Don't wait for someone to come and handle an incident. Let our
                  systems do the work for you and automate processes.
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Create rules to configure what happens when a machine malfunctions
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Notify relevant stakeholders automatically when an incident occurs.
                  </li>

                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Change machine operational parameter as a response to another specific trigger.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <Slider {...settings}>
                  <img src={Act1} className="w-[100%] access" />
                  <img src={Act2} className="w-[100%] access" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActIiot;
