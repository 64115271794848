import React from "react";
import { useState } from "react";
import monitore from "../assets/images/Monitore.png";
import control from "../assets/images/Control.png";
import automate from "../assets/images/Automate.png";
import monitoreRes from "../assets/images/monitore-res.png";
import controlRes from "../assets/images/control-res.png";
import automateRes from "../assets/images/automate-res.png";
import Fade from "react-reveal/Fade";

const OurApproach = () => {
  const [imageSrc, setImageSrc] = useState(
    require("../assets/images/Default-Flow-Chart.png")
  );
  const [resimage, SetResimage] = useState(
    require("../assets/images/Default-flow-chart-res.png")
  );
  const handleImageRes = (newImage) => {
    SetResimage(newImage);
  };
  const handleButtonHover = (newImage) => {
    setImageSrc(newImage);
  };

  return (
    <div>
      <div className="our-approch">
        <div className=" our-approch-res">
          <div className="p-[3rem] md:pb-3 pb-0">
            <Fade bottom>
              <h1 className="heading-text-gradient font-semibold text-[48px]">
                Our Internet-of-Things Solutions
              </h1>
              <hr className="gradient-color" />
              <p className=" pt-10 pb-10 text-lg text-white text-justify">
                We enable connectivity and intelligence for physical assets
                ranging from singular devices to entire facilities with an
                end-to-end solution built on our powerful IoT framework,
                custom-tailored for your use-case.
              </p>
              <h2 className="pb-14  text-white text-[1.75rem] md:text-[2.5rem]">
                General IoT Topology
              </h2>
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <div className="Flow-container px-3 lg:px-[3rem] md:pt-0 md:mt-[-20px] pt-[0] sm:block hidden ">
                <img
                  src={imageSrc}
                  className="flow-container-img"
                  alt="Displayed Image"
                />
              </div>
              <div className="Flow-container px-3 lg:px-[3rem] md:pt-0 md:mt-[-20px] pt-[0] sm:hidden block">
                <img
                  src={resimage}
                  className="flow-container-img"
                  alt="Displayed Image"
                />
              </div>
              <div className="sm:block hidden">
                <div className="grid sm:grid-cols-3 mt-16">
                  <button
                    className="text-[26px] font-medium text-[#07D4FA] p-5 sm:mx-5 monitore"
                    onMouseEnter={() => handleButtonHover(monitore)}
                  >
                    Monitor
                  </button>
                  <button
                    className="text-[26px] font-medium text-[#07D4FA] p-5 sm:mx-5 monitore"
                    onMouseEnter={() => handleButtonHover(control)}
                  >
                    Control
                  </button>
                  <button
                    className="text-[26px] font-medium text-[#07D4FA] p-5 sm:mx-5 monitore"
                    onMouseEnter={() => handleButtonHover(automate)}
                  >
                    Automate
                  </button>
                </div>
              </div>
            </Fade>
            <div className="grid sm:hidden grid-cols-1 mt-16">
              <Fade bottom>
                <button
                  className="text-[26px] font-medium text-[#07D4FA] p-5 mx-20 monitore"
                  onMouseEnter={() => handleImageRes(monitoreRes)}
                >
                  Monitor
                </button>
              </Fade>
              <Fade bottom>
                <button
                  className="text-[26px] font-medium text-[#07D4FA] p-5 mx-20 monitore"
                  onMouseEnter={() => handleImageRes(controlRes)}
                >
                  Control
                </button>
              </Fade>
              <Fade bottom>
                <button
                  className="text-[26px] font-medium text-[#07D4FA] p-5 mx-20 monitore"
                  onMouseEnter={() => handleImageRes(automateRes)}
                >
                  Automate
                </button>
              </Fade>
            </div>
          </div>
          <a href="/about">
            <span className="know-more-2">Know More</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default OurApproach;
