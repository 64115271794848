import React from "react";
import M1 from "../../../assets/images/Monitoring/Monitoring-View.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Monitoring = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-2 items-center justify-items-center">
        <div className="xl:pl-[3rem]">
          <div className="">
            <h2 className="text-[#07D4FA] font-medium text-[28px] md:text-[42px]  lg:text-[50px]  leading-10 lg:leading-[5rem]  mb-3 lg:mb-6">
              Occupancy monitoring solutions
            </h2>

            <ul className="pt-5 text-black text-[18px] font">
              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Zone-wise occupancy density with alerts for energy wastage in
                  unoccupied zones.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Select custom layout views across blocks/floors of spaces.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Entry and exit point monitoring
                </li>
              </div>

              {/* <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Monitor time-based energy consumption trends in EV charging to
                  identify peak load times
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Snapshot of current parking bay occupancy across floors
                </li>
              </div> */}
            </ul>
          </div>
        </div>
        <div className="connect-image-conatiner lg:pt-[2rem]">
          <img src={M1} alt="" className="monito" />
        </div>
      </div>
    </div>
  );
};

export default Monitoring;
