import React from "react";
import S1 from "../../../assets/images/Access-Security/Access-Security.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccessControl = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-2 items-center justify-items-center">
        <div className="xl:pl-[3rem]">
          <div className="">
            <h2 className="text-[#07D4FA] font-medium text-[28px] md:text-[42px] lg:text-[50px]  leading-10 lg:leading-[5rem]  mb-3 lg:mb-6">
              Access Control & Security Solutions
            </h2>

            <ul className="pt-5 text-black text-[18px] font">
              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Space booking showing availability schedule, amenities and
                  remote lock/unlock control
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Integrate digital payment gateways and track transactions and
                  monetization metrics
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Customizable navigation pane with custom views, dashboards and
                  feature pages.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Access log showing personnel/visitors entering/exiting and
                  details of associated RF passes or temporary tickets with
                  ability to control zone-wise access
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="connect-image-conatiner lg:pt-[2rem]">
          <img src={S1} alt="" className="Security-img" />
        </div>
      </div>
    </div>
  );
};

export default AccessControl;
