import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import facility from "../../../assets/images/facility.png";
import facility2 from "../../../assets/images/Facility-2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FacilityManagement = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    // pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <>
      <div className="smart-parking-res facility-main-container lg:pt-[5rem] lg:pb-[3rem] pb-16">
        <div className="">
          <div className="block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="Access-res lg:p-0 sm:p-[4px] heading-text-gradient font-semibold  text-[35px] md:text-[42px]  lg:text-[50px]  leading-10 lg:leading-[5rem]  mb-3 lg:mb-6">
                  Faciltiy Management Solutions
                </h2>
                <p className="pt-2 lg:w-[80%] text-[24px] text-justify text-black font-medium ">
                  Managing physical assets and maintenance activities are always
                  either ad-hoc or carried out at pre defined time periods. We
                  enable a dynamically changing operational schedules for
                  monitoring and control by providing you with the operational
                  metrics to achieve high productivity.
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Control assets like cooling fans, Water Pumps, Lighting etc
                    from a single dashboard.
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Analyze consumption statistics to manage peak load hours.
                  </li>

                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Get notified about breakdowns immediately.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <Slider {...settings}>
                  <img src={facility} className="w-[100%] access" />
                  <img src={facility2} className="w-[100%] access" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityManagement;
