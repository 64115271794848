import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import access from "../../../assets/images/Access.png";
import access1 from "../../../assets/images/Access-2.jpg";

const AccessSecurity = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    // pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <div>
      <div className="smart-parking-res access-main-container lg:pt-[5rem] lg:pb-[3rem]  pb-16">
        <div>
          <div className="block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="Access-res heading-text-gradient lg:p-0 sm:p-[4px] font-semibold text-[35px] md:text-[42px] lg:text-[50px]  leading-[50px] lg:leading-[5rem]  mb-3 lg:mb-6">
                  Access Control & Security Solutions
                </h2>
                <p className="pt-2 lg:w-[80%] text-[24px] text-justify text-black font-medium ">
                  Single point access to view real time feed, manage access and
                  monitor activity across an entire building. We've done it all.
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    View real time entry / exit details of personnel.
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Monitor and manage access for all personnel using RFID tags.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <Slider {...settings}>
                  <img src={access} className="w-[100%] access" />
                  <img src={access1} className="w-[100%] access" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessSecurity;
