import React from "react";
import NavBar from "../Layout/NavBar";
import Fade from "react-reveal/Fade";

const HeroSolution = () => {
  return (
    <div>
      <div className="w-full Our-Solution-Container">
        <NavBar />
        <Fade bottom>
          <h1 className=" text-center text-white text-[70px] font-semibold p-[4.5%]">
            Smart Building Solutions
          </h1>
        </Fade>
      </div>
      <div className="Elevate">
        <div>
          <Fade bottom>
            <h2 className="pl-0 heading-text-gradient font-medium text-[40px] mb-3">
              Elevate your buildings with a true digital Identity
            </h2>
            <p className="text-[18px] leading-8 text-justify mt-4">
              We understand what it takes to maintain and operate a building to
              maintain the highest level of efficiency and lowest operating
              costs. Our Smart Building solutions offer a mix a services and
              products that integrate operations like parking, maintenance,
              occupancy, HVAC control and others on a single platform for a
              unified single point access to everything in your building.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HeroSolution;
