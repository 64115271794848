import React from "react";
import Ms1 from "../../../assets/images/Facality/Ms-View.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ManagementSolutions = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-2 items-center justify-items-center">
        <div className="xl:pl-[3rem]">
          <div className="">
            <h2 className="text-[#07D4FA] font-medium text-[27px] md:text-[42px]  lg:text-[50px]  leading-10 lg:leading-[5rem]  mb-3 lg:mb-6">
              Faciltiy Management Solutions
            </h2>

            <ul className="pt-5 text-black text-[18px] font">
              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Zone-wise control and monitoring of independent or VRV HVAC
                  systems
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Zone-wise lighting control with digital dimming and color
                  temperature tuning
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Consumption statistics showing energy consumed across load
                  categories allowing for data-driven optimization and cost
                  savings.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Customize to see very specific parameters like cost of
                  utilization and overall usage.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Get real time alerts about asset failures.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Control different assets in a building and view their status
                  in real time.
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="connect-image-conatiner lg:pt-[2rem]">
          <img src={Ms1} alt="" className="Facility-img" />
        </div>
      </div>
    </div>
  );
};

export default ManagementSolutions;
