import React from "react";
import connect from "../../../assets/images/Connect/Connect.png";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Connect = () => {
  return (
    <div className="">
      <div className="grid lg:grid-cols-2 items-center justify-items-center">
        <div className="xl:pl-[3rem]">
          <div className="md:pt-[5rem]">
            <h2 className="text-[#07D4FA] font-medium text-[50px] mb-3 lg:mb-6 connect">
              Connect
            </h2>

            <ul className="pt-5 text-black text-[18px] font">
              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Customize your operational area into areas and zones. These
                  can be used to differentiate between processes or functions.
                  Can add all zone specific parameters here based on actual
                  operations.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  One stop access to the major functions of the portal.
                  Customizable to suit application requirements.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className=" pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem] ">
                  Customize for how long you would like to store and analyze
                  your data.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  View existing areas and zones. Use these to modify/view zone /
                  area parameters.
                </li>
              </div>

              <div className="flex mb-2">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-[#2E9EE0] pr-3 mt-2"
                />
                <li className="pb-1 lg:pb-3 text-black font-medium text-[14px] ss:text-[15px] lg:text-[17px] text-justify lg:pr-[2rem]">
                  Have a look at the configuration of your existing devices in a
                  zone and modify them when required.
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="connect-image-conatiner lg:pt-[7rem]">
          <img src={connect} alt="" className="Connect-img" />
        </div>
      </div>
    </div>
  );
};

export default Connect;
