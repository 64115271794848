import React from "react";

import NavBar from "../../Layout/NavBar";

const MobilityHero = () => {
  return (
    <div>
      <div>
        <div className="w-full our-mobility-container">
          <NavBar />
          <h1 className=" text-center text-gradient text-[50px] sm:text-[80px] font-bold py-[17%] sm:px-[30%] ">
            Mobility Coming Soon
          </h1>
        </div>
      </div>
    </div>
  );
};

export default MobilityHero;
