import React from "react";
import NavBar from "../Layout/NavBar";
import Fade from "react-reveal/Fade";
const HeroPageOurTeam = () => {
  return (
    <div>
      <div className="w-full Our-Main-Container">
        <NavBar />
        <div className="flex text-center flex-col  ">
          <div className="p-[4.5%]">
            <Fade bottom>
              <h1 className="text-gradient text-[70px] font-semibold">
                {" "}
                Our Team
              </h1>
            </Fade>
            <Fade bottom>
              <p className="text-white text-[20px] font-normal mt-1">
                Get to Know Our Team Passionate Individuals Fueling Our
                Growthand Progress
              </p>
            </Fade>
            <a href="/career">
              <Fade bottom>
                <button
                  className="join-us-button  m-[auto] mt-[2rem]"
                  role="button"
                >
                  Join our Team
                </button>
              </Fade>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPageOurTeam;
