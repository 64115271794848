import React from "react";

const SolutionForm = ({
  HandleInputChange,
  solutions,
  setSolutions,

  onSubmit,
  setSolution,
  solution,
  isSubmitting,
  isShown,
  resMsg,
}) => {
  const handlesolutionClick = (sol) => {
    console.log(sol);
    const updatedsolution = solution.includes(sol)
      ? solution.filter((item) => item !== sol)
      : [...solution, sol];

    console.log(updatedsolution);
    setSolution(updatedsolution);
    setSolutions((prevData) => ({
      ...prevData,
      solution: updatedsolution,
      selectionType: "solution",
    }));
    console.log("solution data", solution);
  };

  const Solution = ["IIoT", "Mobility", "Smart buildings solutions"];
  return (
    <div>
      {/* USer Details Start */}
      <form action="" onSubmit={onSubmit}>
        <div className="user-details block w-full lg:flex flex-wrap justify-between mt-6">
          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Name<span className="required">*</span>
            </span>
            <input
              autoComplete="off"
              type="text"
              name="name"
              value={solutions.name}
              onChange={HandleInputChange}
              placeholder="Please enter your full name"
              required
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Company
            </span>
            <input
              autoComplete="off"
              type="text"
              name="company"
              value={solutions.company}
              onChange={HandleInputChange}
              placeholder="Please enter company name"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              E-mail
            </span>
            <input
              autoComplete="off"
              type="email"
              name="email"
              value={solutions.email}
              onChange={HandleInputChange}
              placeholder="Please enter your E-Mail ID"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>

          <div className="input-box">
            <span className="block font-medium text-[20px] lg:text-[23px] text-black">
              Phone
            </span>
            <input
              autoComplete="off"
              type="text"
              name="phone"
              value={solutions.phone}
              onChange={HandleInputChange}
              placeholder="Please enter your valid phone number"
              className="text-[10px] md:text-[14px] italic formInput"
            />
          </div>
        </div>

        {/* Select solutions */}

        <div>
          <h3 className="text-[20px] lg:text-[23px] font-semibold text-black ">
            Choose a solutions
          </h3>
          <div className="block md:flex flex-wrap mt-2">
            {Solution.map((sol, index) => (
              <div
                key={index}
                className={`service mr-3 mt-5 ${
                  solution.includes(sol) ? "select" : ""
                }`}
                onClick={() => handlesolutionClick(sol)}
              >
                <p className=" font-medium text-[16px]">{sol}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-9">
          <textarea
            cols="3"
            rows="5"
            name="description"
            value={solutions.description}
            onChange={HandleInputChange}
            placeholder="Tell us about your project"
            className="text-[10px] md:text-[14px] italic formInputText"
          ></textarea>
        </div>
        {isShown && <div className="text-red-500 font-medium">{resMsg}</div>}

        <div className="mt-10 text-center">
          {isSubmitting ? (
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : (
            <button type="submit" className="submit-button">
              Submit
            </button>
          )}
        </div>
      </form>

      {/* USer Details End */}
    </div>
  );
};

export default SolutionForm;
