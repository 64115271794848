import React from "react";
import SmartParkings from "../../../assets/images/SmartPark.png";
import SmartParkings2 from "../../../assets/images/SmartParking-2.jpg";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SmartParking = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    // pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <div>
      <div className="smart-parking-res smart-main-container  lg:pt-[5rem] lg:pb-[3rem] pb-16">
        <div>
          <div className=" block lg:flex items-center">
            <div className="w-full lg:w-[45%] p-[3rem] lg:pt-0">
              <div>
                <h2 className="heading-text-gradient lg:p-0 sm:p-[4px]  font-semibold  text-[35px] md:text-[42px] lg:text-[50px]  leading-10 lg:leading-[5rem]  mb-3 lg:mb-6">
                  Smart Parking
                </h2>
                <p className="pt-2 lg:w-[80%] text-[24px] text-justify text-black font-medium ">
                  Manning parking lots to manage vehicle movement and payments
                  is a thing of the past with our parking solutions.
                </p>
                <ul className="pt-5 text-black text-[18px] font">
                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    See real time activity of all vehicles entering /exiting.
                  </li>

                  <li className="pb-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    View real time occupancy of slots in a parking area.
                  </li>

                  <li>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-[#4BAE4F] pr-3"
                    />
                    Integrate parking payments based on usage time.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-[55%]">
              <div>
                <Slider {...settings}>
                  <img src={SmartParkings} className="w-[100%] access" />
                  <img src={SmartParkings2} className="w-[100%] access" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartParking;
