import React from "react";
import HeroSolution from "../components/OurSolutions/HeroSolution";
import MeetsEfficiency from "../components/OurSolutions/MeetsEfficiencyNew";
import Footer from "../components/Layout/Footer";
import SmartBulidingCard from "../components/OurSolutions/SmartBulidingCard";

const SmartBuilding = () => {
  return (
    <div>
      <HeroSolution />

      {/* <div className="lg:hidden block">
        <SmartParking />
        <AccessSecurity />
        <FacilityManagement />
      </div> */}
      <SmartBulidingCard />
      <MeetsEfficiency />
      <Footer />
    </div>
  );
};

export default SmartBuilding;
