import React from "react";
import Connect from "./Connect";
import Analyze from "./Analyze";
import Measure from "./Measure";
import Act from "./Act";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ScrollSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 1500,
  };
  return (
    <>
      <Slider
        {...settings}
        className="scroll-slider bg-[#dffffc] p-10 xl:p-12 xl:pt-0 mx-28 my-10 filter"
      >
        <div>
          <Connect />
        </div>
        <div className="connect-container">
          <Measure />
        </div>
        <div className="connect-container">
          <Analyze />
        </div>
        <div className="connect-container">
          <Act />
        </div>
      </Slider>
    </>
  );
};

export default ScrollSlider;
