import React from "react";
import "../../index.css";
import Team from "./OurTeam";
import Fade from "react-reveal/Fade";
const OurManagement = () => {
  return (
    <>
      <div className="Our-Managment">
        <Fade bottom>
          <h2 className="pl-0 heading-text-gradient font-medium text-[40px] pb-[2rem] ">
            Our Management
          </h2>
        </Fade>

        <div className="our-team-res mt-4 grid grid-cols-1 justify-items-center md:grid-cols-2 gap-[3rem] lg:grid-cols-4 xl:grid-cols-4">
          <Fade bottom>
            <div className=" mb-[30px] ">
              <img src="/teamimg/aditya.png" className="our-image " />
              <div className="ms-1">
                <div className="flex">
                  <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                    Aditya Arora
                  </h3>
                </div>
                <p className="text-[16px] text-white">CEO eLogic Engineering</p>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className=" mb-[30px] join-img-res ">
              <img src="/teamimg/Tarun.png" className="our-image" />
              <div className="ms-1">
                <div className="flex">
                  <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                    Tarun Pakala
                  </h3>
                </div>
                <p className="text-[16px] text-white">CTO eLogic Engineering</p>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className=" mb-[30px] join-img-res">
              <img src="/teamimg/rupali-modi.png" className="our-image" />
              <div className=" ">
                <div className="flex">
                  <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                    Rupali Modi
                  </h3>
                </div>
                <p className="text-[16px] text-white">Managing Director</p>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className=" mb-[30px] join-img-res">
              <img src="/teamimg/rajasekharan.png" className="our-image" />
              <div className="ms-1">
                <div className="flex">
                  <h3 className="our-text mt-4 mb-3 text-[32px] heading-text-gradient">
                    Rajasekharan N.K.
                  </h3>
                </div>
                <p className="text-[16px] text-white">Business Advisor</p>
              </div>
            </div>
          </Fade>
        </div>
        {/* Our Team Start */}
        <div className="mt-20">
          <Fade bottom>
            <h2 className="pl-0  heading-text-gradient font-medium text-[40px] pb-[2rem]">
              Our Team
            </h2>
          </Fade>
          <Team />
        </div>
        {/* Our Team End */}
      </div>
    </>
  );
};

export default OurManagement;
