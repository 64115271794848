import React from "react";
import Smart from "./SmartBuliding/SmartParking";
import Acc from "./SmartBuliding/AccessSecurity";
import Fac from "./SmartBuliding/FacilityManagement";
import Moniter from "./SmartBuliding/OccupancyMonitor";

const SmartBulidingCard = () => {
  return (
    // Smart Buliding Card Animation
    <div>
      <main>
        <ul id="cards">
          <li className="card" id="card_1">
            <div className="card__content">
              <div>
                <Smart />
              </div>
            </div>
          </li>
          <li className="card" id="card_2">
            <div className="card__content">
              <div>
                <Acc />
              </div>
            </div>
          </li>
          <li className="card" id="card_3">
            <div className="card__content">
              <div>
                <Fac />
              </div>
            </div>
          </li>
          <li className="card" id="card_4">
            <div className="card__content">
              <div>
                <Moniter />
              </div>
            </div>
          </li>
        </ul>
      </main>
    </div>
  );
};

export default SmartBulidingCard;
